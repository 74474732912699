<template>
	<v-content>
		<v-container fluid class="pt-12 mt-12">
			<div>
				<v-row justify="center">
					<v-col cols="12" sm="8" md="4">
						<v-card class="elevation-0">
							<div class="title title-container mb-10">
								<h1>Mot de passe oublié</h1>
							</div>
							<v-card-text>
								<br />
								<v-form v-model="valid">
									<v-text-field
										v-model="email"
										:rules="emailRules"
										label="Adresse email"
										outlined
										required
										color="black"
									></v-text-field>
								</v-form>
								<p class="success-mdp" v-if="messageOk != ''">{{ messageOk }}</p>
								<p class="alert-mdp" v-if="errorMessage != ''">
									{{ errorMessage }}
								</p>
							</v-card-text>
							<div class="button-validate">
								<v-btn :disabled="!valid" large depressed color="#15c39a" class="validez" @click="validate()">
									<span class="white--text">Changer de mot de passe</span>
								</v-btn>
							</div>
							<div class="back-login-container">
								<router-link to="/login"
									><span style="color: black">Retour au formulaire de connexion</span></router-link
								>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</div>
		</v-container>
	</v-content>
</template>

<script>
export default {
	name: 'ForgottenPassword',

	data: () => ({
		valid: false,
		messageOk: '',
		errorMessage: '',
		email: '',
		emailRules: [
			(v) => !!v || "L'email est un champ obligatoire",
			(v) => /.+@.+/.test(v) || "L'email n'est pas valide",
		],
	}),
	watch: {
		email: function () {
			this.messageOk = this.errorMessage = '';
		},
	},
	methods: {
		validate() {
			this.$http
				.post('/api/users/resetpassword/send-mail', {
					email: this.email,
				})
				.then((response) => {
					this.messageOk = response.data.message;
				})
				.catch((err) => {
					this.errorMessage = err.response.data.message;
				});
		},
	},
};
</script>

<style scoped>
.back-login-container {
	text-align: center;
	padding-bottom: 30px;
	cursor: pointer;
	text-decoration: underline;
}
.title-container {
	text-align: center;
}
.alert-mdp {
	text-align: center;
	color: red;
}
.success-mdp {
	text-align: center;
	color: #15c39a;
}
.button-validate {
	text-align: center;
	padding-top: 20px;
	padding-bottom: 30px;
}
</style>
