<template>
	<ForgottenPasswordComponent />
</template>

<script>
import ForgottenPasswordComponent from '@/components/ForgottenPassword.vue';

export default {
	name: 'ForgetPassword',
	components: {
		ForgottenPasswordComponent,
	},
};
</script>
